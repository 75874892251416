
            
            import { runInjectionScript, swapDocument } from "@clerk/astro/internal";

            // Taken from https://github.com/withastro/astro/blob/e10b03e88c22592fbb42d7245b65c4f486ab736d/packages/astro/src/transitions/router.ts#L39.
            // Importing it directly from astro:transitions/client breaks custom client-side routing
            // even when View Transitions is disabled.
            const transitionEnabledOnThisPage = () => {
              return !!document.querySelector('[name="astro-view-transitions-enabled"]');
            }

            if (transitionEnabledOnThisPage()) {
              const { navigate, swapFunctions } = await import('astro:transitions/client');

              document.addEventListener('astro:before-swap', (e) => {
                const clerkComponents = document.querySelector('#clerk-components');
                // Keep the div element added by Clerk
                if (clerkComponents) {
                  const clonedEl = clerkComponents.cloneNode(true);
                  e.newDocument.body.appendChild(clonedEl);
                }

                e.swap = () => swapDocument(swapFunctions, e.newDocument);
              });

              document.addEventListener('astro:page-load', async (e) => {
                await runInjectionScript({
                  ...{"signInUrl":"/login","signUpUrl":"/signup","afterSignOutUrl":"/login","sdkMetadata":{"version":"1.4.10","name":"@clerk/astro","environment":"production"}},
                  routerPush: navigate,
                  routerReplace: (url) => navigate(url, { history: 'replace' }),
                });
              })
            } else {
              await runInjectionScript({"signInUrl":"/login","signUpUrl":"/signup","afterSignOutUrl":"/login","sdkMetadata":{"version":"1.4.10","name":"@clerk/astro","environment":"production"}});
            }